import React from "react";
import { Link } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { HiOutlineBell } from "react-icons/hi";
import {IoOptionsOutline,IoMailOpenOutline} from "react-icons/io5";

const Header = ({ open, setOpen, shrinker, setShrink, setShrinker }) => {
  const isTab = window.matchMedia(
    "only screen and (max-width: 1024px)"
  ).matches;
  const hamButtonClass = () => {
    if(isTab){
      return "flex flex-col justify-center cursor-pointer";
    }
    else if(shrinker){
      return "hidden";
    }
    else if(!shrinker){
      return "flex flex-col justify-center cursor-pointer";
    }
  } 
  return (
    <>
      <div className="w-full h-16 sticky top-0 bg-gray-50 flex justify-between items-center">
        <div className="ml-4 flex">
          <div
            onClick={() => {
              setOpen(true);
              setShrinker(true);
              setShrink(2);
            }}
            className={hamButtonClass()}
          >
            <div className="w-6 rounded h-[2px] my-[2px] bg-blue-700"></div>
            <div className="w-6 rounded h-[2px] my-[2px] bg-blue-700"></div>
            <div className="w-6 rounded h-[2px] my-[2px] bg-blue-700"></div>
          </div>
          <div className="ml-2 flex">
            <div className="rounded-l-md bg-gray-100 flex justify-center items-center">
              <BiSearch className="w-8 h-8 px-1 text-gray-500" />
            </div>
            <input
              type="text"
              placeholder="Search.."
              className="focus:outline focus:outline-4 focus:outline-[#5ca8ff49] w-48 rounded-r-md bg-gray-100 py-1 text-xl caret-slate-500"
            />
          </div>
        </div>
        <div className="flex justify-around items-center w-1/2 lg:w-2/6">
          <p><HiOutlineBell className="w-6 h-6"/></p>
          <p><IoOptionsOutline className="w-6 h-6"/></p>
          <p><IoMailOpenOutline className="w-6 h-6"/></p>
          <Link to="/" className="mr-4">
            <img
              src="https://dummyimage.com/600x400/000/fff"
              alt="logo"
              className="w-12 h-12 rounded-full object-cover"
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header;
