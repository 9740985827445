import React from 'react';
import { useParams } from 'react-router-dom';

const RoutePage = () => {
  const {parent,child,grandChild} = useParams();
  const r = Math.floor(Math.random()*255);
  const g = Math.floor(Math.random()*255);
  const b = Math.floor(Math.random()*255);
  return (<>
  <div style={{background:`rgb(${r},${g},${b})`}} className="flex justify-center items-center w-full h-[100vh]">
    <p>Route = {parent} + {child} + {grandChild}</p>
  </div>
  </>)
}

export default RoutePage