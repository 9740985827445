import React from 'react'
import Content from '../components/Content'

const Home = () => {
  return (
    <div>
        <Content/>
    </div>
  )
}

export default Home