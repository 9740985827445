import React from "react";
import { BsPerson } from "react-icons/bs";
import {BiLockAlt} from "react-icons/bi";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { giveAccess } from "../slices/authSlice.js";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
  return (
    <div className="w-full h-screen bg-gray-100 flex gap-4 flex-col md:flex-row justify-center items-center">
      <div className="w-[96%] md:w-[40%] h-80 rounded bg-white flex justify-center items-center">
        <form onSubmit={(e)=>{e.preventDefault();navigate("/")}} className="w-[80%] h-[86%] flex flex-col justify-evenly">
          <p className="text-2xl text-gray-700 font-semibold">Login</p>
          <p className="text-gray-500">Sign In to your account</p>
          <div className="flex w-full">
            <div className="rounded-l flex justify-center items-center bg-gray-100 border border-gray-500 border-opacity-50 px-1.5">
              <BsPerson className="text-gray-500" />
            </div>
            <input
              placeholder="Username"
              type="text"
              className="p-1 w-full text-sm placeholder:text-gray-500 focus:outline-8 focus:outline-sky-500 border rounded-r border-gray-500 border-opacity-50 bg-gray-100"
            />
          </div>
          <div className="flex w-full">
            <div className="rounded-l flex justify-center items-center bg-gray-100 border border-gray-500 border-opacity-50 px-1.5">
              <BiLockAlt className="text-gray-500" />
            </div>
            <input
              placeholder="Password"
              type="password"
              className="p-1 w-full text-sm placeholder:text-gray-500 focus:outline-8 focus:outline-sky-500 border rounded-r border-gray-500 border-opacity-50 bg-gray-100"
            />
          </div>
          <div className="w-full h-[20%] flex justify-between items-center">
            <button onClick={()=>dispatch(giveAccess())} className="py-1 px-3 rounded bg-sky-500">Login</button>
            <p className="text-blue-600 underline text-center cursor-pointer">
              Forgot
              <br />
              Password
            </p>
          </div>
        </form>
      </div>
      <div className="w-[96%] md:w-[40%] h-80 rounded bg-sky-500 flex flex-col justify-evenly items-center">
        <p className="text-2xl font-semibold text-white text-center mx-4">
          Sign Up
        </p>
        <p className="text-white text-center mx-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores
          odit omnis exercitationem dicta nesciunt inventore vero porro debitis
          eligendi unde!
        </p>
        <Link to='/register'><button className="mx-4 bg-sky-400 py-1 px-3 rounded text-lg">
          Register
          <br />
          Now!
        </button></Link>
      </div>
    </div>
  );
};

export default Login;
