import React from "react";
import { BiLockAlt } from "react-icons/bi";
import { BsPerson } from "react-icons/bs";
import {MdOutlineAlternateEmail} from "react-icons/md";
import { useDispatch } from "react-redux";
import { giveAccess } from "../slices/authSlice.js";
import { useNavigate } from "react-router-dom";

const Register = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
  return (
    <>
      <div className="bg-gray-100 w-full h-screen flex justify-center items-center">
        <form onSubmit={(e)=>{e.preventDefault();navigate("/")}} className="w-80 rounded-lg md:w-2/5 h-96 bg-white border-2 border-gray-200 border-opacity-70 flex flex-col justify-evenly items-center">
          <p className="text-3xl font-semibold text-gray-700 w-4/5 md:w-[90%]">Register</p>
          <p className="text-gray-500 w-4/5 md:w-[90%]">Create your account</p>
            <div className="flex w-4/5 md:w-[90%]">
              <div className="rounded-l flex justify-center items-center bg-gray-100 border border-gray-500 border-opacity-50 px-1.5">
                <BsPerson className="text-gray-700" />
              </div>
              <input
                placeholder="Username"
                type="text"
                className="p-1 w-full text-sm placeholder:text-gray-500 focus:outline-8 focus:outline-sky-500 border rounded-r border-gray-500 border-opacity-50 bg-gray-100"
              />
            </div>
            <div className="flex w-4/5 md:w-[90%]">
              <div className="rounded-l flex justify-center items-center bg-gray-100 border border-gray-500 border-opacity-50 px-1.5">
                <MdOutlineAlternateEmail className="text-gray-700" />
              </div>
              <input
                placeholder="Email"
                type="email"
                className="p-1 w-full text-sm placeholder:text-gray-500 focus:outline-8 focus:outline-sky-500 border rounded-r border-gray-500 border-opacity-50 bg-gray-100"
              />
            </div>
            <div className="flex w-4/5 md:w-[90%]">
              <div className="rounded-l flex justify-center items-center bg-gray-100 border border-gray-500 border-opacity-50 px-1.5">
                <BiLockAlt className="text-gray-700" />
              </div>
              <input
                placeholder="Password"
                type="password"
                className="p-1 w-full text-sm placeholder:text-gray-500 focus:outline-8 focus:outline-sky-500 border rounded-r border-gray-500 border-opacity-50 bg-gray-100"
              />
            </div>
            <div className="flex w-4/5 md:w-[90%]">
              <div className="rounded-l flex justify-center items-center bg-gray-100 border border-gray-500 border-opacity-50 px-1.5">
                <BiLockAlt className="text-gray-700" />
              </div>
              <input
                placeholder="Repeat Password"
                type="password"
                className="p-1 w-full text-sm placeholder:text-gray-500 focus:outline-8 focus:outline-sky-500 border rounded-r border-gray-500 border-opacity-50 bg-gray-100"
              />
            </div>
            <button onClick={()=>dispatch(giveAccess())} className="w-4/5 md:w-[90%] rounded bg-green-600 py-1 text-gray-800 hover:bg-green-700 hover:text-gray-900">Create Account</button>
        </form>
      </div>
    </>
  );
};

export default Register;
