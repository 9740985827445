import React from 'react'

const Footer = () => {
  return (
    <div className='bg-gray-50 h-12 flex justify-between items-center'>
        <div className="ml-4 flex">
            <p>Manjesh UI</p>
            <a className='text-blue-800 underline ml-1' href="https://linkedin.com/in/manjesh-kumar-sharma">Visit Linkedin</a>
        </div>
        <div className="mr-4 flex">
          <p>Created with React, Router & Tailwind</p>
        </div>
    </div>
  )
}

export default Footer