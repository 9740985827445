import React from "react";

const Content = () => {
  return (
    <div className="w-full h-screen bg-pink-400 flex justify-center items-center">
      Content
    </div>
  );
};

export default Content;
