import React, { useRef, useEffect } from "react";
import { BiDownArrow } from "react-icons/bi";
import data from "../navData.json";
import { Link } from "react-router-dom";
import { GrCloudlinux } from "react-icons/gr";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const Sidebar = ({
  open,
  setOpen,
  shrink,
  shrinker,
  setShrink,
  setShrinker,
}) => {
  const myRef = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  const controlNavClick = (e) => {
    if (e.currentTarget.nextElementSibling.classList.contains("hidden")) {
      e.currentTarget.nextElementSibling.classList.add("flex");
      e.currentTarget.nextElementSibling.classList.remove("hidden");
      e.currentTarget.lastElementChild.classList.add("rotate-180");
      return;
    } else {
      e.currentTarget.nextElementSibling.classList.remove("flex");
      e.currentTarget.nextElementSibling.classList.add("hidden");
      e.currentTarget.lastElementChild.classList.remove("rotate-180");
      return;
    }
  };
  const isTab = window.matchMedia(
    "only screen and (max-width: 1024px)"
  ).matches;
  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      if (isTab) {
        setOpen(false);
      }
    }
  };
  const controlSideBarWidth = () => {
    if (!open) {
      return "hidden transition-all";
    } else if (!isTab && open === true) {
      if (shrinker === false) {
        return;
      }
      if (shrink === 1 && shrinker === true) {
        return "fixed left-0 flex flex-col top-12 items-center z-10 w-20 bg-gradient-to-b from-[#242839] to-[#2f384d] h-[100vh] overflow-y-scroll transition-all";
      }
      if (shrink === 2 && shrinker === true) {
        return "fixed left-0 flex flex-col top-12 items-center z-10 w-64 bg-gradient-to-b from-[#242839] to-[#2f384d] h-[100vh] overflow-y-scroll transition-all";
      }
      if(shrink === 3 && shrinker === true){
        return "fixed left-0 flex flex-col top-12 items-center z-10 w-64 bg-gradient-to-b from-[#242839] to-[#2f384d] h-[100vh] overflow-y-scroll transition-all"; 
      }
    } else if (isTab && open === true) {
      return "fixed left-0 flex flex-col top-12 items-center z-10 w-64 bg-gradient-to-b from-[#242839] to-[#2f384d] h-[100vh] overflow-y-scroll transition-all";
    }
  };
  const controlSideBanner = () => {
    if (!open) {
      return "hidden transition-all";
    } else if (!isTab && open === true) {
      if (shrinker === false) {
        return;
      }
      if (shrink === 1 && shrinker === true) {
        return "h-12 w-20 bg-gray-900 top-0 left-0 fixed";
      }
      if (shrink === 2 && shrinker === true) {
        return "h-12 w-64 bg-gray-900 top-0 left-0 fixed";
      }
      if (shrink === 3 && shrinker === true) {
        return "h-12 w-64 bg-gray-900 top-0 left-0 fixed";
      }
    } else if (isTab && open === true) {
      return "h-12 w-64 bg-gray-900 top-0 left-0 fixed";
    }
  };
  return (
    <div
      ref={myRef}
      onMouseEnter={() => {
        if(shrink === 2){ return; }
        else if (shrinker === true && shrink === 1) {
          setShrink(3);
        }
      }}
      onMouseLeave={() => {
        if (shrinker === true && shrink === 3) {
          setShrink(1);
        }
      }}
    >
      <div className={controlSideBanner()}>
        <div className="absolute hidden right-0 w-6 hover:text-gray-50 h-full lg:flex justify-center items-center text-gray-300 cursor-pointer">
          {shrink === 3 ? (
            <FaAngleRight
              onClick={() => {
                setShrinker(true);
                setShrink(2);
              }}
            />
          ) : (
            <FaAngleLeft
              onClick={() => {
                setShrinker(true);
                setShrink(3);
              }}
            />
          )}
        </div>
        <div
          className={
            shrink === 1
              ? "w-20 h-full flex justify-center items-center text-gray-300 hover:text-gray-50 cursor-pointer"
              : "w-56 h-full flex justify-center items-center text-gray-300 hover:text-gray-50 cursor-pointer"
          }
        >
          <GrCloudlinux />
          <p className={shrink === 1 ? "hidden" : "ml-2"}>REACT JS</p>
        </div>
      </div>
      <div className={controlSideBarWidth()}>
        {data?.map((parent, index) => (
          <div
            key={index}
            className="bg-orange-700 cursor-pointer w-4/5 rounded my-2 text-2xl flex flex-col text-gray-300"
          >
            <div
              onClick={(e) => {
                controlNavClick(e);
              }}
              className="flex justify-around items-center hover:text-gray-50"
            >
              <p className={shrink === 1 ? "hidden" : ""}>{parent?.name}</p>
              <BiDownArrow />
            </div>
            <div className="hidden bg-orange-800 w-full flex-col">
              {parent?.childrens?.map((child, childIndex) => (
                <div key={childIndex} className="flex flex-col w-full">
                  <div
                    onClick={(e) => controlNavClick(e)}
                    className="flex justify-around items-center text-xl  hover:text-gray-50"
                  >
                    <p className={shrink === 1 ? "hidden" : ""}>
                      {child?.name}
                    </p>
                    <BiDownArrow className={shrink === 1 ? "hidden" : ""}/>
                  </div>
                  <div className="hidden flex-col bg-orange-900 w-full">
                    {child?.childrens?.map((grandChild, grandIndex) => (
                      <div
                        key={grandIndex}
                        className="flex justify-around items-center text-xl hover:text-gray-50"
                      >
                        <Link
                          className={shrink === 1 ? "hidden" : ""}
                          to={`/route/${parent.name}/${child.name}/${grandChild.name}`}
                        >
                          {grandChild?.name}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
