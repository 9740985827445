import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuthenticated:false,
}

export const authSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    giveAccess:(state,payload)=>{
        state.isAuthenticated = true;
    },
    logOut:(state)=>{
        state.isAuthenticated = false;
    }
  },
})

export const { giveAccess,logOut } = authSlice.actions

export default authSlice.reducer