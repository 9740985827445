import React, { useState } from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";

const App = () => {
  const [open, setOpen] = useState(false);
  const [shrinker, setShrinker] = useState(false);
  const [shrink, setShrink] = useState(0);
  const isTab = window.matchMedia(
    "only screen and (max-width: 1024px)"
  ).matches;
  const shrinkClass = () => {
    // on phone shrinkClass should not work
    // onClick setShrink as well as setSchrinker but if phone both of them should be 0 and false respectively
    if (!isTab) {
      if (shrinker === false) {
        return;
      }
      if (shrink === 1 && shrinker === true) {
        return "ml-20 w-[calc(100%-5rem)]";
      }
      if (shrink === 2 && shrinker === true) {
        return "ml-64 w-[calc(100%-16rem)]";
      }
      if(shrink === 3 && shrinker === true){
        return "ml-20 w-[calc(100%-5rem)]";
      }
    }
    else if(isTab){
      return "";
    }
  };
  return (
    <>
      <div className={shrinkClass()}>
        <Header open={open} setOpen={setOpen} shrinker={shrinker} setShrink={setShrink} setShrinker={setShrinker}/>
        <Sidebar open={open} setOpen={setOpen} shrink={shrink} shrinker={shrinker} setShrink={setShrink} setShrinker={setShrinker} />
        <Outlet />
        <Footer />
      </div>
    </>
  );
};

export default App;
